import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  pb: '0',
  mx: 2,
  px: 0,
  w: 'calc(100% - 16px)',
};

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  borderBottomLeftRadius: 'none',
  borderBottomRightRadius: 'none',
  bg: 'blackAlpha.300',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  textTransform: 'uppercase',
  mb: '1',
};

export const EachWayText: CSSObject = {
  color: 'black',
  fontWeight: 'medium',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'rgba(0, 0, 0, 0.25)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  fontWeight: 'black',
  '&&': {
    color: 'beta.300',
  },
};

export const ReviewEstText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'beta.300',
  fontWeight: 'black',
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'bold',
  fontFamily: 'accent',
  fontSize: 'sm',
  color: 'blackAlpha.700',
  bgGradient: 'linear(to-b, rgba(255, 191, 54, 1), rgba(238, 162, 0, 1))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  borderRightColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: 'base',
  h: '34px',
  w: '34px',
  p: 0,
});

export const InputBetSlip: CSSObject = {
  border: 'none',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'black',
  fontFamily: 'body',
  h: '40px',
  pl: '2.8rem',
  boxShadow: 'none',
  ':first-child': {
    pl: '2',
  },
};

export const FlexiReview: CSSObject = {
  pl: '8',
  "[class$='ReviewStake']": {
    color: 'gamma.100',
  },
};

export const FlexReviewExotic: CSSObject = {
  textAlign: 'left',
  "[class$='ReviewStake']": {
    color: 'gamma.800',
  },
};

export const TreasureChestIcon: CSSObject = {
  color: 'gamma.400',
};
