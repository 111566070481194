import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'xl',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  textShadow: 'none',
  color: 'white',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  textTransform: 'uppercase',
  mb: ['4px', null, '0px'],
};
