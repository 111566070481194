import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  scrollButtonGroup: {
    variant: 'filter',
    bg: 'none',
    pos: 'relative',
    pb: '4',
    mb: '2',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: '#192B3B',
    borderRadius: 'none',
    boxShadow: '0px 1px 0px 0px #FFFFFF12',
  },
  pageHeader: { fontSize: '2xl' },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    p: ['2', null, null, '4'],
  },
  boxWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'md',
    gap: '1',
  },
  headingIcon: {
    color: 'alpha.600',
    sx: {},
  },
  propositionButton: {
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  boxSportWrapper: {
    display: 'none',
  },
  buttonSport: {
    borderColor: 'blackAlpha.400',
    color: 'alpha.800',
    _hover: {
      color: 'alpha.400',
    },
  },
  buttonAllSports: {
    _active: {
      bg: 'black',
      border: 'none',
      color: 'white',
      '&:after': {
        display: 'none',
      },
    },
  },
};
