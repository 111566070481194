import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {
      boxSize: '4',
    },
  },
  iconButtonScrollRight: {
    bgGradient: 'linear(to-b, gamma.400, gamma.400)',
    color: 'alpha.800',
    border: 'none',
    borderRadius: 'sm',
    borderLeftRadius: 'none',
    h: 9,
    w: 'auto',
    minW: '4',
    _hover: {
      bgGradient: 'linear(to-b, gamma.300, gamma.300)',
    },
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {
      boxSize: '4',
    },
  },
  iconButtonScrollLeft: {
    bgGradient: 'linear(to-b, gamma.400, gamma.400)',
    color: 'alpha.800',
    border: 'none',
    borderRadius: 'sm',
    borderRightRadius: 'none',
    h: 9,
    w: 'auto',
    minW: '4',
    _hover: {
      bgGradient: 'linear(to-b, gamma.300, gamma.300)',
    },
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  flexWrapper: {
    bg: 'delta.500',
    borderRadius: 'md',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.35)',
  },
  buttonGroupSports: {
    h: 9,
    display: 'inline-flex',
    flex: '1',
    overflowX: 'auto',
    p: '1',
    spacing: '1',
    whiteSpace: 'nowrap',
    bg: 'beta.700',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset,0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'md',
    sx: { '::-webkit-scrollbar': { display: 'none' } },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    pl: '1',
    pos: 'relative',
    spacing: '1',
  },
  scrollIndicator: {
    w: 2,
    h: '0.5',
    bg: 'gamma.500',
    pos: 'absolute',
    bottom: 'px',
    transition: 'all 0.2s ease',
  },
};
