import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { CustomButtonProps } from '@/components/Button/Button';

export const betCardErrorFlexTextProps: FlexProps = {
  color: 'red.100',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'red.100',
  bg: 'red.700',
  borderRadius: 'md',
  w: '95%',
  mx: 'auto',
  mt: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
};

export const BetCardContainer = (): CSSObject => ({
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  color: 'white',
  mb: '-2.5',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  borderRadius: 'md',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  'div[class*="FormsHStack"]': {
    ':nth-child(n+3)': {
      mb: 2.5,
      pt: 2.5,
      borderTop: '1px dashed rgba(0, 0, 0, 0.25)',
      boxShadow: '0px 1px 0px inset rgba(255, 255, 255, 0.07)',
    },
  },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'white',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'bold',
  fontSize: 'sm',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'white',
  mb: 0,
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'beta.400',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  '&&': {
    color: 'gamma.800',
  },
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  ml: '8',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexPlacedContainer: CSSObject = {
  p: '2',
};

export const BetPlaced: CSSObject = {
  bgGradient: 'linear(to-b, white, white)',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const BetPropositionClosedButton: CSSObject = {
  bg: 'alpha.700',
  boxShadow: 'md',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
};
export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
};

export const BetCardSVG = (header: boolean) => ({
  color: 'white',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
  flexDirection: 'column-reverse',
  gap: '1',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);

export const EventRule: CSSObject = {
  color: 'gamma.800',
};

export const BetPropositionClosedError: CSSObject = {
  bg: 'rgba(28, 46, 77, 0.7)',
  borderRadius: 'md',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};

export const buttonStakeProps: CustomButtonProps = {
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
  h: '42px',
  color: 'white',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  maxW: '47px',
};

export const BetOdds: CSSObject = {
  mb: '0.5',
};

export const buttonBonusBetsProps = (
  isBonusBet: boolean,
  isInReview: boolean
): CustomButtonProps => ({
  display: 'flex',
  variant: 'unstyled',
  size: 'xs',
  px: '3',
  alignSelf: 'flex-start',
  textTransform: 'capitalize',
  w: isInReview ? '100%' : 'auto',
  isDisabled: isInReview,
  border: '1px',
  borderColor: 'alpha.400',

  sx: {
    '.chakra-button__icon': {
      display: 'none',
    },
  },

  _disabled: {
    boxShadow: 'none',
    border: 'none',
    bg: 'blackAlpha.300',
    color: 'beta.50',
    opacity: '1',
    '& > span': {
      _first: {
        color: 'green.300',
        mr: '1',
      },
    },
  },

  ...((): CustomButtonProps => {
    if (isBonusBet && isInReview)
      return {
        mt: '-3',
        bg: 'blackAlpha.700',
        borderRadius: 'md',

        sx: {
          '.chakra-button__icon': {
            display: 'block',
            color: 'green.300',
            mr: '0.5',
          },
        },

        spanProps: {
          fontFamily: 'roboto',
          fontWeight: 'medium',
          fontSize: '2xs',
          pt: '0.5',
          textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
          textTransform: 'uppercase',
        },

        _hover: {
          border: 'none',
        },

        _disabled: {
          '&&': {
            bg: 'blackAlpha.600',
            border: 'none',
          },
        },
      };

    if (isBonusBet)
      return {
        bg: 'alpha.800',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'beta.500',
        borderRadius: 'md',
        h: '26px',
        spanProps: {
          fontFamily: 'Staatliches',
          textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
          fontWeight: 'normal',
          fontSize: '11px',
          px: '2',
          pt: '0.5',
        },
      };

    return {
      bg: 'none',
      bgGradient: 'linear(to-b, alpha.200, alpha.400)',
      boxShadow:
        '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
      color: 'white',
      borderRadius: 'md',
      spanProps: {
        fontFamily: 'Staatliches',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        fontWeight: 'normal',
        fontSize: '11px',
        px: '2',
        pt: '0.5',
      },
    };
  })(),
});

export const buttonBetPropositionClosedProps: CSSObject = {
  variant: 'unstyled',
  bg: 'gamma.600',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  borderRadius: 'base',
  color: 'white',
  fontWeight: 'bold',
  fontSize: 'xs',
  px: '3',
  py: '2',
  paddingLeft: '3',
  paddingRight: '3',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '1',
};

export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
  pr: '2',
  pl: '2.5',
  pb: 4,
};

// THEMING MYSTERY BET
export const mysteryBetFooterPrimaryContainerWrapperProps: CSSObject = {
  w: '95%',
  bg: 'alpha.800',
  borderRadius: '5px',
  boxShadow: 'md',
  color: 'beta.500',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2',
  p: '2',
  mt: '1',
  m: '3',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const mysteryBetFooterSecondaryContainerWrapperProps: CSSObject = {
  w: '95%',
  bg: 'alpha.800',
  borderRadius: '5px',
  boxShadow: 'md',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '2',
  p: '2',
  mt: '1',
  m: '3',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 'xs',
};

export const ExoticsNumberContainer: CSSObject = {
  minW: '6',
};

export const BetCardVStack: CSSObject = {
  pb: '0',
};
